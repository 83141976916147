<script setup lang="ts">
import { DataObject } from "o365-dataobject";
import { $t } from "o365-utils";

import { computed, onMounted} from "vue";

import * as drawer from "./controls.Drawer.ts";
import { isMobileApp } from "./utils.ts";
import { contextName } from "./controls.Context.ts";
import Filter from "./controls.filter.Filter.js"; 

import MPill from "./components.Pill.vue";

const props = defineProps<{
    filter: Filter;
    dataObject?: DataObject
}>();

const pills = computed(() => {
    const pills = [];
    for (let field of props.filter.fields) {
        for (let pill of field.getPills()) {
            pills.push(pill);
        }
    }

    return pills;
});

const clearAllFitlers = (): void => {
    props.filter.removeSavedFilter();
    props?.dataObject?.filterObject?.clear();
}        

function openContextSelector() {
    drawer.openContextSelector();
}
</script>

<template>
    <template v-if="pills.length || isMobileApp">
        <div class="flex-shrink-0 d-flex gap-2 hide-scrollbar" style="overflow-x: auto;">
            <template v-if="isMobileApp">
                <m-pill color="primary-subtle" 
                    style="max-width: 12rem;" 
                    @click="openContextSelector()"
                >
                    {{ $t("Context") }}: {{ contextName }}
                </m-pill>
            </template>
            <template v-for="pill in pills">
                <m-pill color="primary-subtle" 
                    style="max-width: 70vw" 
                    @click="pill.click" 
                    @close="pill.close"
                >
                    {{ pill.text }}
                </m-pill>
            </template>
            <template v-if="dataObject?.filterObject?.activeFilter">
                <m-pill
                    :key="dataObject?.filterObject.filtersListDO?.current.FilterName"
                    style="max-width: 70vw"
                    color="primary-subtle" 
                    :size="'lg'"
                    :fontWeight="'fw-semibold'"
                    @close="clearAllFitlers"
                >
                    {{ props.dataObject?.filterObject?.activeFilter?.FilterName  }}
                </m-pill>
            </template>
        </div>
    </template>
</template>

<style scoped>
    .hide-scrollbar {
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { display: none; }
    }
</style>
